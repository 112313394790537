.home-container {
    display: flex;
    flex-grow: 1;
    width: 100%;
    min-height: 100%;
    height: auto;
    flex-direction: column;
    margin-top: 5px;
}

.home-header {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    
}

.home-header > img {
    width: 80%;
    height: auto;
}

.home-prompt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-prompt > p {
    font-family: var(--base-font); 
    color: #929292;
    font-weight: bold;
}

.home-form {
    width: 60%;
    align-self: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    
    padding: 10px;
}

.home-error {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--base-font);
    color: var(--error);
    font-size: small;
}

.home-pickup {
    display: flex;
    flex-direction: column;
    grid-column: 1 / 3;
    font-family: var(--base-font);
    padding: 5px;
}

.home-dropoff {
    display: flex;
    flex-direction: column;
    grid-column: 3 / 5;
    font-family: var(--base-font); 
    padding: 5px;
}

.home-pickup > p, .home-dropoff > p {
    color: var(--primary);
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
}

#a {
    grid-column: 1 / 3;
}

#b {
    grid-column: 3 / 5;
}

#c {
    grid-column: 1 / 4;
}

#d {
    grid-column: 4 / 5;
}

#e {
    grid-column: 1 / 3;
}

#f {
    grid-column: 3 / 5;
}

#g {
    grid-column: 1 / 3;
}

#h {
    grid-column: 3 / 5;
}

#i {
    grid-column: 2 / 4;
}



@media only screen and (max-width: 600px) {

    .home-prompt > p {
        padding: 3%;
        font-size: 0.8em;
    }


    .home-form {
        width: auto;
    }

    .home-pickup, .home-dropoff{
        grid-column: 1 / 5;
        font-size: 0.8em;
    }

  }

