.item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0.8px solid #ccc;
}

.item-container > .item-image-wrapper  > img {
   width: 100%;
   height: auto;
}

.item-container > p {
    font-family: "Open Sans",sans-serif;
    margin: 0px
}

.item-category {
    font-size: 0.7em;
    font-style: italic;
    color: #999999;
}

p.item-price {
    font-size: 1.7em;
    color: #57bf6d;
    margin-bottom: 5px;
}

.item-price > span {
    font-size: 0.4em;
}

.item-image-wrapper {
    display: inline-block;
    position: relative;
    text-align: center;
}

.item-quick-view {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: opacity 500ms;
}

.item-quick-view:hover {
    opacity: 1;
}

.item-quick-view  > h3{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
    padding-bottom: 5px;
    color: #fff;
    font-size: 0.9em;
    background-color: rgba(0, 0, 0, 0.37);
}


.item-quick-view > h3:hover {
    background-color: black;
}

@media only screen and (max-width: 600px) {
    
    .item-container > p {
        margin: 2px;
    }

  }