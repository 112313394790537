.sorting-container {
    padding: 20px 10%;
}

.sorting-box {
    padding: 5px;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    display: flex;
    align-items: center;
}

.sorting-icons {
    color: rgb(199, 199, 199);
    margin: 5px;
    padding: 5px;
    font-size: 30px;
    border: 1px solid #ddd;
    cursor: pointer;
}

.sorting-icons:hover {
    color: #3498db;
    border-color: #3498db;
}

.sorting-dropdown-container {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
}

.sorting-dropdown {
    font-family: var(--base-font);
    padding: 6px;
    color: rgb(199, 199, 199);;
    border: 1px solid #ddd;
    cursor: pointer;
}

.sorting-dropdown:hover{
    border-color: #3498db;
}

.sorting-dropdown:focus{
    border-color: #3498db;
}


.sorting-dropdown > option {
    color: black;
}

.sorting-dropdown-container > p {
    font-size: 0.8em;
}


@media only screen and (max-width: 600px) {
    
    .sorting-dropdown-container > p {
        display: none;
    }

    .sorting-dropdown-container {
        flex-grow: 0;
    }

    .sorting-box {
        justify-content: center;
    }

  }