@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
    --primary: #0C9EFF;
    --primary-variant: #1565c0;
    --secondary: #ff9800;
    --secondary-variant: #f57c00;
    --background: #eceff1;
    --surface: #ffffff;
    --error: #c62828;
    --on-primary: #ffffff;
    --on-secondary: #000000;
    --on-background: #ffffff;
    --on-surface: #292a2b;
    --on-error: #ffffff;

    --custom-gray: #C7CACC;

    --base-font: 'Roboto', sans-serif;
    --alt-font: "Open Sans",sans-serif;

    --nav-color: #1e73be;
    --nav-color-alt: #57bf6d;
}

html,
body, #root {
    width: 100%;
    height: 100%;
}

.App {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100%;
    height: auto;
    overflow: hidden;
}

.btn-primary {
    background-color: var(--primary);
    border: 1px solid var(--primary);
    color: var(--surface);
    padding: 5px;
    font-family: var(--base-font);
    margin: 5px;
    font-weight: bold;
}

.btn-primary:hover {
    background-color: #fff;
    
    color: var(--primary);
}

.btn-primary.large {
    width: 100%;
    margin: 0;
    text-align: center;
    font-size: 1.2em;
}

.btn-secondary {
    background-color: var(--secondary);
    border: 1px solid var(--secondary);
    color: var(--surface);
    padding: 5px;
    font-family: var(--base-font);
    margin: 5px;
    font-weight: bold;
}

.btn-secondary:hover {
    background-color: #fff;
    
    color: var(--secondary);
}

.btn-secondary.large {
    width: 100%;
    margin: 0;
    text-align: center;
    font-size: 1.2em;
}

.nav-container {
    display: flex;
    padding: 20px;
    align-items: center;
    font-family: var(--base-font);
    font-size: 0.8em;
    background-color: #fff;
    color: var(--nav-color);
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.55);

}

.nav-image {
    width: 20%;
    height: auto;
}

.nav-container > a:link, .nav-item-end > a:link {
    text-decoration: none;
    color: var(--nav-color);
}

.nav-container > a:visited, .nav-item-end > a:visited {
    text-decoration: none;
    color: var(--nav-color);
}

.nav-container > a:hover, .nav-item-end > a:hover {
    text-decoration: none;
    color: rgb(166, 32, 255);
}

.nav-item {
    padding-left: 20px;
    padding-right: 20px;
}

.nav-item-end {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
}



@media only screen and (max-width: 600px) {
    .nav-item {
        display: none;
    }

    .nav-item-end {
        display: none;
    }


  }
.cart-container {
    position: relative;
}

.cart-wrapper:first-child:hover{
    color: purple;
}

.cart-wrapper:hover > p{
    background-color: purple ;
    border-color: purple;
}


.cart-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
    cursor: pointer;
}

.cart-wrapper > p{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border: 1px solid var(--primary);
    background-color: var(--primary);
    margin: 0;
    border-radius: 50px;
    color: #fff;
}

.cart-icon {
    font-size: 15px;
    margin: 5px;
}

.cart-checkout-container {
    width: 300px;
    height: auto;
    border: 1px solid #ccc;
    position: absolute;
    background-color: white;
    z-index: 100;
}

.disappear {
    display: none;
}

@media only screen and (max-width: 600px) {
    .cart-checkout-container {
        transform: translateX(-80%);
    }

    .cart-container {
        font-display: flex;
    }
    

  }
.cartitem-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 5px;
    font-family: var(--base-font);
    color: black;
    border-bottom: 1px solid #ddd;
    position: relative;
}

.cartitem-container > img {
    width: 100%;
    height: auto;
}

.cartitem-infowrapper {
    padding: 2px;
}

.cartitem-infowrapper > h4{
    margin: 0;
    font-size: 15px;
}

.cartitem-infowrapper > span {
    display: flex;
    align-items: center;
    color: #929292;
    font-size: 0.85em;
}

.cartitem-infowrapper > span > h5 {
   margin: 0;
   color: black;
   margin-right: 2px;
}

.cartitem-price {
    display: flex;
    align-items: center;
    padding-top: 3px;
}

.cartitem-price > h5 {
    margin: 2px;
    color: #57bf6d;
    font-size: 14px;
}

.cartitem-price > p {
    color: #929292;
    margin: 2px;
    font-size: 12px;
}

.cartitem-remove {
    position: absolute;
    top: 0;
    right: 0;

    width: 20px;
    height: 20px;

    font-weight: bold;

    border: 1.5px solid #929292;
    color: #929292;
    margin: 5px;
    border-radius: 45px;

    text-align: center;
    cursor: pointer;
}

.cartitem-remove:hover {
    background-color: #929292;
    color: white;
}
.sidebar-container {
    display: none;
}

.sidebar-icon {
    font-size: 2em;
}

.sidebar-menu {
    position: fixed;
    top: 0;
    left: -1000%;
    
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100%;
    height: auto;

    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.4s ease-in-out ;
}

.fadeIn {
    background-color:rgba(0, 0, 0, 0.411);
    left: 0;
    z-index: 5000;
}


.sidebar-header {
    position: relative;
    left: -500px;

    display: flex;
    justify-content: center;
    width: 50%;
    padding: 10px 5px 10px 5px;
    background-color: var(--surface);
    min-width: 300px;
    border-bottom: 1px solid #ddd ;
}

.sidebar-header > img {
    width: 90%;
    height: auto;
}

.sidebar-main {
    position: relative;
    left: -500px;

    display: flex;
    flex-direction: column;
    width: 50%;
    min-width: 300px;

    flex-grow: 1;
    background-color: var(--surface);
}

.slideIn {
    left: 0;
    transition: left 0.8s ease-in-out;
}

.sidebar-item {
    padding: 10px;
    text-decoration: none;
    font-size: 1.2em;

    color: var(--nav-color);
}

.sidebar-item:hover {
    background-color: #ccc;
}

.sidebar-submenu {
    margin-left: 15px;
}

@media only screen and (max-width: 600px){
    .sidebar-container {
        display: flex;
        flex-grow: 1;
    }
}


.home-container {
    display: flex;
    flex-grow: 1;
    width: 100%;
    min-height: 100%;
    height: auto;
    flex-direction: column;
    margin-top: 5px;
}

.home-header {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    
}

.home-header > img {
    width: 80%;
    height: auto;
}

.home-prompt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-prompt > p {
    font-family: var(--base-font); 
    color: #929292;
    font-weight: bold;
}

.home-form {
    width: 60%;
    align-self: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    
    padding: 10px;
}

.home-error {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--base-font);
    color: var(--error);
    font-size: small;
}

.home-pickup {
    display: flex;
    flex-direction: column;
    grid-column: 1 / 3;
    font-family: var(--base-font);
    padding: 5px;
}

.home-dropoff {
    display: flex;
    flex-direction: column;
    grid-column: 3 / 5;
    font-family: var(--base-font); 
    padding: 5px;
}

.home-pickup > p, .home-dropoff > p {
    color: var(--primary);
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
}

#a {
    grid-column: 1 / 3;
}

#b {
    grid-column: 3 / 5;
}

#c {
    grid-column: 1 / 4;
}

#d {
    grid-column: 4 / 5;
}

#e {
    grid-column: 1 / 3;
}

#f {
    grid-column: 3 / 5;
}

#g {
    grid-column: 1 / 3;
}

#h {
    grid-column: 3 / 5;
}

#i {
    grid-column: 2 / 4;
}



@media only screen and (max-width: 600px) {

    .home-prompt > p {
        padding: 3%;
        font-size: 0.8em;
    }


    .home-form {
        width: auto;
    }

    .home-pickup, .home-dropoff{
        grid-column: 1 / 5;
        font-size: 0.8em;
    }

  }


.input-container {
    font-family: var(--base-font);
    display: flex;
    flex-flow: column-reverse;
    margin-bottom: 10px;
    margin: 2px;
    padding: 2px;
    max-width: 100%;
    overflow: hidden;
}

.input-container > input, .input-container > label {
    transition: all 0.2s;
   touch-action: manipulation;
}

.input-container > input{
    font-size: 1.3em;
    
    border: 1px solid rgba(121, 162, 250, 0.486);
    -webkit-appearance: none;
    border-radius: 3px;
    padding: 2px;
    cursor: text;
}


.input-container > input:focus{
    outline: 0;
    border: 1px solid var(--primary);
}

.input-container > label {
    font-size: 0.7em;
    color: var(--primary);
    padding: 2px;
    padding-left: 5px;
    padding-bottom: 5px;
  }

  .input-container > input:-ms-input-placeholder + label {
    cursor: text;
    max-width: 66.66%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(0, 2.125rem) scale(1.5);
  }

  .input-container > input:placeholder-shown + label {
    cursor: text;
    max-width: 66.66%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(0, 2.125rem) scale(1.5);
  }

  .input-container > input::-webkit-input-placeholder {
    opacity: 0;
    -webkit-transition: inherit;
    transition: inherit;
  }

  .input-container > input:focus::-webkit-input-placeholder {
    opacity: 0;
  }


  .input-container > input:not(:-ms-input-placeholder) + label {
  transform: translate(0, 0) scale(1);
  cursor: pointer;
}


  .input-container > input:not(:placeholder-shown) + label,
  .input-container > input:focus + label {
  transform: translate(0, 0) scale(1);
  cursor: pointer;
}

.calendar-container {
    display: flex;
    height: 40px;
    align-items: center;
    border: 1.5px solid rgba(121, 162, 250, 0.486);
    cursor: pointer;
    
}

.calendar-container  > input{
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    border-right: 1px solid #ccc;
    text-align: center;
    font-family: var(--base-font);
    letter-spacing: 2px;
    color: #929292;
    cursor: pointer;
}

.calendar-icon {
    font-size: 45px;
    padding: 8px;
    color: #1e73be;
    cursor: pointer;
}


.uploader-container {
    grid-column: 1/5;
    padding: 5px;
    margin: 5px;
    border: 1px solid rgba(121, 162, 250, 0.486);
    font-family: var(--base-font); 
}

.uploader-container >h4 {
    margin: 2px;
    color: var(--primary);
}

.uploader-input {
    display: flex;
    align-items: center;
}

.uploader-input > p{
    flex-grow: 1;
    border-bottom: 1px solid rgba(121, 162, 250, 0.486);
}

.uploader-input > input[type="file"] {
    display: none;
}

.uploader-chooser {
    display: flex;
    flex-grow: 1;
    align-items: center;
    font-family: var(--base-font);
}

.uploader-chooser > h5 {
    background-color: var(--primary);
    border: 1px solid var(--primary);
    color: var(--surface);
    padding: 5px;
    margin: 5px;
    font-weight: bold;
}
.uploader-chooser > h5:hover {
    background-color: #fff;
    color: var(--primary);
}

.uploader-chooser > p {
    margin: 0;
    flex-grow: 1;
    font-size: 0.9em;
}

.progress-container {
    background-color: rgb(196, 196, 196);
    flex-grow: 1;
    height: 10px;
    margin: 5px;
    border-radius: 20px;
}

.progress-bar {
    background-color: rgb(118, 247, 118);
    height: 10px;
    border-radius: 20px;
    transition: width 2s
}
.product-container {
    display: flex;
    min-height: 100%;
    height: auto;
    flex-grow: 1;
    flex-direction: column;
    margin-top: 5px;
}

.product-banner {
    font-family: var(--base-font);
    background-color: #f5f5f5 ;
    padding: 20px 10%;
}

.product-banner > h2 {
    margin: 0;
    padding: 0;
    color: #333333;
}
.product-banner > p {
    color: #929292;
   
}

.product-area {
    padding: 20px 10%;
    min-height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
    grid-column-gap: 5px;
    column-gap: 5px;
    grid-row-gap: 5px;
    row-gap: 5px;
}

@media only screen and (max-width: 600px) {
    
    .product-area {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 5px;
        column-gap: 5px;
        grid-row-gap: 5px;
        row-gap: 5px;
    }

  }
.sorting-container {
    padding: 20px 10%;
}

.sorting-box {
    padding: 5px;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    display: flex;
    align-items: center;
}

.sorting-icons {
    color: rgb(199, 199, 199);
    margin: 5px;
    padding: 5px;
    font-size: 30px;
    border: 1px solid #ddd;
    cursor: pointer;
}

.sorting-icons:hover {
    color: #3498db;
    border-color: #3498db;
}

.sorting-dropdown-container {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
}

.sorting-dropdown {
    font-family: var(--base-font);
    padding: 6px;
    color: rgb(199, 199, 199);;
    border: 1px solid #ddd;
    cursor: pointer;
}

.sorting-dropdown:hover{
    border-color: #3498db;
}

.sorting-dropdown:focus{
    border-color: #3498db;
}


.sorting-dropdown > option {
    color: black;
}

.sorting-dropdown-container > p {
    font-size: 0.8em;
}


@media only screen and (max-width: 600px) {
    
    .sorting-dropdown-container > p {
        display: none;
    }

    .sorting-dropdown-container {
        flex-grow: 0;
    }

    .sorting-box {
        justify-content: center;
    }

  }
.item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0.8px solid #ccc;
}

.item-container > .item-image-wrapper  > img {
   width: 100%;
   height: auto;
}

.item-container > p {
    font-family: "Open Sans",sans-serif;
    margin: 0px
}

.item-category {
    font-size: 0.7em;
    font-style: italic;
    color: #999999;
}

p.item-price {
    font-size: 1.7em;
    color: #57bf6d;
    margin-bottom: 5px;
}

.item-price > span {
    font-size: 0.4em;
}

.item-image-wrapper {
    display: inline-block;
    position: relative;
    text-align: center;
}

.item-quick-view {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: opacity 500ms;
}

.item-quick-view:hover {
    opacity: 1;
}

.item-quick-view  > h3{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
    padding-bottom: 5px;
    color: #fff;
    font-size: 0.9em;
    background-color: rgba(0, 0, 0, 0.37);
}


.item-quick-view > h3:hover {
    background-color: black;
}

@media only screen and (max-width: 600px) {
    
    .item-container > p {
        margin: 2px;
    }

  }
.details-container {
    flex-grow:1;
    font-family: var(--base-font);
    
}

.details-banner {
    font-family: var(--base-font);
    background-color: #f5f5f5 ;
    padding: 20px 10%;
}

.details-banner > h2 {
    margin: 0;
    padding: 0;
    color: #333333;
}

/* -------------------left hand panel----------------- */

.details-panel {
    padding: 20px 10%;
    margin-top: 4%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 5px;
    column-gap: 5px;
}

.details-panel > img {
    /* width: 100%; */
    height: auto;
    max-width: 100%;
}


/* -------------------right hand panel----------------- */
.details-item {
    padding: 10px 5%;
}

.details-part1 {
    font-size: 25px;
    border-bottom: 1px solid #ddd;
}

.details-part1 > h4 {
    margin: 0;
    margin-bottom: 20px;
}

.details-part2 {
    color: #929292;
    font-size: 15px;
    border-bottom: 2px solid #ddd;
}

.details-part2 > h5 {
    font-size: 35px;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #57bf6d;
}

/* -------------------3rd Panel----------------- */
.details-part3 {
    padding: 10px;
    border-bottom: 2px solid #ddd;
    display: flex;
    flex-direction: column;
}

.details-part3-timers > p {
    font-family: "Open Sans",sans-serif;
    color: #929292;
    margin: 5px;
    margin-left: 0;
    font-size: 0.9em;
}

.details-part3-timers {
    border-top: 1px solid #ddd;
    padding-bottom: 5px;
}

.details-cart-input {
    display: flex;
    
}

.details-counter {
    width: 150px;
    height: 50px;
    display: flex;
    padding: 5px;
    color: #929292;
}

.minus {
    flex-grow: 1;
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.count {
    flex-grow: 1;
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
}

.plus {
    flex-grow: 1;
    border: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.details-clock { 
    display: flex;
    margin-top: 5px;
    padding: 5px;
    border: 1.5px solid rgba(121, 162, 250, 0.486);
    width: 100%;
}

.react-time-picker__wrapper {
    border: none !important;
} 

.react-time-picker__inputGroup__input {
    font: var(--base-font) !important;
}

.detail-error {
    font-size: 0.8em;
    color: var(--error);
    letter-spacing: 2px;
}

/* -------------------4rd Panel----------------- */

.details-part4 {
    font-size: 12px;
    border-bottom: 1px solid #ddd;
    padding: 5px;
}

.details-part4 > span {
    display: flex;
    align-items: center;
    color: #929292;
}

.details-part4 > span > p {
    margin: 2px;
    font-weight: bold;
    color: black;
}

/* -------------------description----------------- */
.details-desc {
    padding: 20px 10%;
}
.details-desc-heading {
    display: flex;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    justify-content: center;
    align-items: center;
}

.details-desc-heading > h3{
    margin: 0;
    padding: 10px;
    font-size: 15px;
    font-family: "Open Sans",sans-serif;
    border-top: 2px solid #1e73be;
    border-bottom: 2px solid #1e73be;
    color:  #1e73be;
}

.details-desc > h5 {
    font-size: 20px;
}

.details-desc > p {
    color: #929292;
    margin: 0;
}

@media only screen and (max-width: 600px) {

    .details-panel {
        padding: 10px 5%;
        margin-top: 4%;
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 5px;
        column-gap: 5px;
    }

  }
.footer-container {
    width: 100%;
    background-color: #222222;
    color: #ffffff;
    font-family: var(--base-font);
}

.footer-top-panel > div > h4 {
    border-left: 2px solid #3498db ;
    padding-left: 10px;
}

.footer-top-panel {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    padding: 10px;
}


/* ------------- Left panel -------------------- */

.footer-panel1 > span {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer-icon {
    border: 1px solid #777777;
    padding: 10px;
    font-size: 40px;
    margin: 5px;
    color: #3498db;
}

.footer-item {
    display: flex;
    flex-direction: column;
}

.footer-item > h5, .footer-item > p {
    margin: 0;
    color: #777777;
}

/* ------------- Middle panel -------------------- */

.footer-panel2 > ul  {
    padding-left: 20px;
    list-style-type: circle;
}

.footer-panel2 > ul > li {
    padding: 5px;
    transition: color 400ms;
}

.footer-panel2 > ul > li > a {
    color: #fff;
    text-decoration: none;
    transition: font-size 400ms, color 400ms;
}

.footer-panel2 > ul > li:hover {
    color: #3498db;
}

.footer-panel2 > ul > li > a:hover {
    color: #3498db;
    font-size: 15px;
}

/* ------------- last panel -------------------- */

.footer-panel3 > ul  {
    padding-left: 20px;
    list-style-type: circle;
}

.footer-panel3 > ul > li {
    padding: 5px;
    transition: color 400ms;
}

.footer-panel3 > ul > li > a {
    color: #fff;
    text-decoration: none;
    transition: font-size 400ms, color 400ms;
}

.footer-panel3 > ul > li:hover {
    color: #3498db;
}

.footer-panel3 > ul > li > a:hover {
    color: #3498db;
    font-size: 15px;
}

.footer-copyright {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: #1b1b1b;
    color: #929292;
    font-size: 12px;
    letter-spacing: 2px;
}



@media only screen and (max-width: 600px) {

    .footer-panel2, .footer-panel3 {
        display: none;
    }

  }
.review-container {
    flex-grow: 1;
}

.review-banner {
    font-family: var(--base-font);
    background-color: #f5f5f5 ;
    padding: 20px 10%;

}

.review-productarea {
    display: flex;
    padding: 20px 10%;
    
}

.review-productarea > table {
    width: 100%;
    text-align: center;
}

.review-table-header {
    font-family: var(--base-font);
    letter-spacing: 0.1em;
    background-color: var(--primary);
    color: white;
    border-bottom: 1px solid #ddd;
}

.review-table-image {
    max-width: 50px;
    width: 10%;
    padding: 5px;
}

.review-table-image > img {
    width: 100%;
    height: auto;
}

.review-table-desc {
    position: relative;
}

.review-table-desc > h5 {
    margin: 0;
    padding: 5px;
    font-family: var(--alt-font);
    font-size: 1em;

    display: flex;
    margin-left: 10px;
}

.review-table-desc > p {
    margin: 0;
    padding: 2px;
    font-size: 0.7em;
    font-family: var(--base-font);

    display: flex;
    margin-left: 10px;
}

.review-table-desc  span {
    color: #929292;
}

.review-table-quantity {
    font-size: 1.2em;
}

.review-table-quantity > h4 {
    color: #929292;
    margin: 0;
    padding: 5px;
}

.review-table-unit {
    font-size: 1.2em;
}

.review-table-unit > h4 {
    margin: 0;
    padding: 5px;
}

.review-table-total {
    font-size: 1.2em;
    color: var(--nav-color-alt);
}

.review-table-total > h4 {
    margin: 0;
    padding: 5px;
}

.review-productarea > table td {
    border-bottom: 1px solid #ddd;
}

.review-user-details {
    
    display: grid;
    grid-template-areas: "heading heading heading edit"
                         "name name name name"
                         "address address address address"
                         "suburb suburb suburb suburb"
                         "country country country country"
                         "email email email email"
                         "number number number number"
                         "pickup pickup pickup pickup"
                         "dropoff dropoff dropoff dropoff"
                         ;
    
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 20px 10%;
    padding: 10px;
    grid-row-gap: 1px;
    row-gap: 1px;
    grid-column-gap: 1px;
    column-gap: 1px;
    border: 1px solid #ccc;
}

.review-edit-btn {
    grid-area: edit;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
}

.review-edit-btn > svg {
    margin-left: 5px;
}

.review-edit-btn > p {
    margin: 0;
}


.review-edit-btn:hover {
    color: var(--primary);
}

.review-user-details > h4, .review-user-details > p {
    margin: 0;
    font-family: var(--base-font);
}

.review-user-details > p {
    font-size: 0.8em;
}

.review-heading {
    grid-area: heading;
    
}

.review-name {
    grid-area: name;
    
}

.review-address {
    grid-area: address;
    
}

.review-suburb {
    grid-area: suburb;
    
}

.review-country {
    grid-area: country;
    
}

.review-email {
    grid-area: email;
    
}

.review-number {
    grid-area: number;
    
}

.review-pickup {
    grid-area: pickup;
}

.review-dropoff {
    grid-area: dropoff;
}

.review-subtotal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10%;
    margin-left: 10%;
    border-top: 1px solid #ddd;
    font-family: var(--base-font);
}

.review-subtotal-wrapper {
    display: flex;
    align-items: center;
}

.review-subtotal-wrapper > h3 {
    color: #929292;
}

.review-subtotal-wrapper > p {
    padding: 5px;
    font-weight: bold;
    color: var(--nav-color-alt);
}

.review-user-details > div > input {
    outline: none;
    border: 1px solid var(--primary);
    padding: 3px;
    font-family: var(--base-font);
}

.review-user-details > div > input:focus {
    border: 2px solid var(--secondary-variant);
}

.btn-primary.editing {
    background-color: #fff;
    color: dimgrey;
    border: 1px solid dimgrey;
}

.review-clock { 
    display: flex;
    margin-top: 5px;
    border: 1px solid var(--primary);
    font-family: var(--base-font);
    font-size: 0.8em;
    width: 160px;
}



.review-dropoff > p, .review-pickup > p{
    font-size: 0.8em;
    margin: 2px;
}

.review-controls {
    margin: 20px 10%;
    border: 1px solid #ccc;
}

.review-delivery-input {
    display: flex;
    flex-direction: column;
    font-family: var(--base-font);
    font-weight: bold;
    padding: 5px;
}

.review-delivery-input > input {
    width: 50px;
}

.review-bond-input, .review-discount-input {
    display: flex;
    flex-direction: column;
    font-family: var(--base-font);
    font-weight: bold;
    padding: 5px;
}

.review-bond-input > input, .review-discount-input > input {
    width: 50px;
}

.check-style {
    color: yellowgreen;
    position: absolute;
    font-size: 1.4em;
    top: 10px;
    right: 10px;
}

.hide {
    display: none;
}


@media only screen and (max-width: 600px) {

    .review-productarea {
        display: flex;
        padding: 20px 1%;
        font-size: 12px;
    }

    .review-table-header {
        font-size: 0.6rem;
    }

    .review-user-details {
        margin: 20px 1%;
    }

    .review-subtotal {
        margin-right: 1%;
        margin-left: 1%;
    }

    .review-table-desc {
        text-align: left;
    }

    .review-controls {
        margin: 20px 1%;
    }
    

  }

.mini-container {
    display: flex;
    height: 30px;
    width: 160px;
    align-items: center;
    border: 1px solid var(--primary);
    cursor: pointer;
    
}

.mini-container  > input{
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    border-right: 1px solid #ccc;
    text-align: center;
    font-family: var(--base-font);
    letter-spacing: 2px;
    color: #929292;
    cursor: pointer;
}

.mini-icon {
    font-size: 40px;
    padding: 8px;
    color: #1e73be;
    cursor: pointer;
}


.quantity-container {
}

.quantity-up {
    color: #929292;
    cursor: pointer;
}

.quantity-value {
    color: #929292;
    font-size: 1em;
    font-weight: bold;
}

.quantity-down {
    color: #929292;
    cursor: pointer;
}
.final-container {
    flex-grow: 1;
}

.final-card {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    min-height: 500px;
    margin-top: 20px;
    border: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    font-family: var(--base-font);

}

.final-card > h4 {
    text-align: center;
}

.final-loader {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.final-message {
    display: flex;
    font-family: var(--alt-font);
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 600px) {

    .final-card {
        min-height: 400px;
    }

  }
.login-container {
    flex-grow:1;
    font-family: var(--base-font);
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-card {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    width: 80%;
    min-width: 300px;
    height: auto;
    padding: 5px;
    background-color: #fafafa;
}

.login-header {
    display: flex;
    justify-content: center;
    color: var(--primary);
}

.login-header > h4 {
    margin: 0;
    padding: 5px;
}

.login-main {
    display: flex;
    flex-direction: column;
    
}

.login-button {
    width: 60%;
    min-width: 200px;
    align-self: center;
}

.login-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--error);
}

.login-persis{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-link {
    color: var(--primary);
    cursor: pointer;
    text-decoration: underline;
}

.login-link:hover {
    color: var(--secondary);
}
.orders-container {
    flex-grow: 1 ;
}


.group-container{
    margin: 10px;
    padding-bottom: 3px;
    background-color: rgba(0, 174, 255, 0.137);
}

.group-header {
    display: flex;
    align-items: center;
    background-color: #ddd;
    cursor: pointer;

    color: var(--nav-color);

    grid-column: 1 /3;
}

.group-header > * {
    margin: 4px;
}
.orderitem-container {
    border: 1px solid #738fb97e;
    margin: 10px;
    padding: 5px;

    background-color: #fff;
}

.orderitem-customer-details{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;

}

.orderitem-cs-dt-header {
    display: flex;
    align-items: center;
    background-color: #ddd;
    cursor: pointer;

    color: var(--nav-color);

    grid-column: 1 / 3;

}

.orderitem-cs-dt-header > * {
    margin: 4px;
}

.orderitem-cs-dt-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 5px;
}

.orderitem-cs-dt-item > *{
    margin: 0;
}

.orderitem-cs-dt-item > p{
    margin-left: 2px;
    font-size: 0.8em;
}

.orderitem-cs-dt-item.full {
    grid-column: 1 / 3;
}

.hidden {
    display: none;
}

.orderitem-cs-dt-item.table{
    flex-direction: column;
}

.orderitem-cs-dt-item > table {
    width: 100%;
    border-collapse: collapse;
}

.orderitem-cs-dt-item > table > tr th {
    text-align: left;
}

.orderitem-id {
    display: flex;
    justify-content: flex-end;
    align-items: right;
    color: var(--primary-variant)
}

.orderitem-id > p {
    font-style: italic;
    font-size: 0.6em;
    margin-bottom: 0;
}

.orderitem-id.full{
    grid-column: 1 / 3;
}

.orderitem-date {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    font-style: italic;
    
    font-size: 0.6em;
    align-items: center;
}

.orderitem-edit {
    padding: 3px;
    padding-right: 10px;
    font-style: normal;

    font-size: 0.7rem;
    font-weight: bold;
    cursor: pointer;
}

.orderitem-edit:hover {
    color: var(--secondary);
}

.orderitem-buttons {
    grid-column: 1 /3;
    display: flex;
    justify-content: center;

}

.orderitem-buttons > button {
    width: 40%;
}

.orderitem-response {
    grid-column: 1 /3;
    color: var(--nav-color-alt);
}

.btn-primary.disabled {
    background-color: #fff;
    color: dimgrey;
    border: 1px solid dimgrey;
}

.edit-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.edit-container > h3 {
    color: var(--nav-color);
}

.edit-error {
    align-self: center;
    font-weight: 400;
    
}

.edit-input-container {
    display: flex;
    flex-direction: column;
    padding: 5px;
    border-bottom: 1px solid #ccc;
}

.edit-input-label {
    font-size: 0.7rem;
    font-weight: bold;
    padding-bottom: 3px;
}

.edit-input-text {
    padding: 5px;
    outline: none;
    border: 0.5px solid var(--nav-color);
    max-width: 300px;
}

.edit-datepicker {
    border-color: var(--nav-color);
    width: 300px;
}

.edit-datepicker > input {
    border-color: var(--nav-color);
}

.edit-clock {
    display: flex;
    margin-top: 5px;
    border: 1px solid var(--nav-color);
    font-family: var(--base-font);
    font-size: 0.8em;
    width: 300px;
}

.edit-divider {
    width: 100%;
    border: 0.5px solid rgb(141, 141, 141);
}

.edit-cart-item {
    border: 1px solid black;
    padding: 3px;
    margin: 2px;
    display: flex;
    align-items: center;
}

.edit-cart-item > p {
    margin: 0;
}

.edit-cart-item > input {
    outline: none;
    border: 0.5px solid #dddddd;
    padding: 3px;
    max-width: 50px;
    margin-right: 10px;
}

.edit-cart-item > input:focus {
    border-color: var(--primary);
}

.edit-cart-item > span {
    color: var(--nav-color-alt);
    margin-left: 2%;
}

.edit-input-dropdown {
    outline: none;
    padding: 5px;
    min-width: 250px;
    width: 50%;
    border: 1px solid #ddd;
}

.edit-input-dropdown:focus {
    border-color: var(--primary);
}

.edit-input-text.readonly {
    border-color: #929292;
    font-size: 18;
}

.edit-buttons {
    display: flex;
    justify-content: center;
}

.edit-buttons > button {
    width: 40%;
}

.edit-loader {
    display: flex;
    justify-content: center;
}

.edit-remove-item {
    margin: 0;
    padding: 3px;
    font-size: 1.4rem;
    color: var(--primary);
    margin-left: auto;
}

.edit-remove-item:hover {
    color: var(--primary-variant)
}
.terms-container {
    min-height: 100%;
    height: auto;
    flex-grow: 1;
    flex-direction: column;
    margin-top: 5px;

    display: grid;
    grid-template-columns: 1fr 1fr;
}

.terms-banner {
    font-family: var(--base-font);
    background-color: #f5f5f5 ;
    padding: 20px 10%;

    grid-column:  1 / 3;
}

.terms-banner > h2 {
    margin: 0;
    padding: 0;
    color: #333333;
}

.terms-content {
    padding: 20px 10%;
    font-family: var(--base-font);
}

.terms-content h3 {
    color: #333333;
}

.terms-content p {
    color: #929292;
}

.terms-content ul {
    color: #929292;
    list-style-type: number;
    font-size: 0.9rem;
}

@media only screen and (max-width: 600px) {
    
    .terms-container {
        display: flex;
    }
  }
