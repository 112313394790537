.footer-container {
    width: 100%;
    background-color: #222222;
    color: #ffffff;
    font-family: var(--base-font);
}

.footer-top-panel > div > h4 {
    border-left: 2px solid #3498db ;
    padding-left: 10px;
}

.footer-top-panel {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    padding: 10px;
}


/* ------------- Left panel -------------------- */

.footer-panel1 > span {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer-icon {
    border: 1px solid #777777;
    padding: 10px;
    font-size: 40px;
    margin: 5px;
    color: #3498db;
}

.footer-item {
    display: flex;
    flex-direction: column;
}

.footer-item > h5, .footer-item > p {
    margin: 0;
    color: #777777;
}

/* ------------- Middle panel -------------------- */

.footer-panel2 > ul  {
    padding-left: 20px;
    list-style-type: circle;
}

.footer-panel2 > ul > li {
    padding: 5px;
    transition: color 400ms;
}

.footer-panel2 > ul > li > a {
    color: #fff;
    text-decoration: none;
    transition: font-size 400ms, color 400ms;
}

.footer-panel2 > ul > li:hover {
    color: #3498db;
}

.footer-panel2 > ul > li > a:hover {
    color: #3498db;
    font-size: 15px;
}

/* ------------- last panel -------------------- */

.footer-panel3 > ul  {
    padding-left: 20px;
    list-style-type: circle;
}

.footer-panel3 > ul > li {
    padding: 5px;
    transition: color 400ms;
}

.footer-panel3 > ul > li > a {
    color: #fff;
    text-decoration: none;
    transition: font-size 400ms, color 400ms;
}

.footer-panel3 > ul > li:hover {
    color: #3498db;
}

.footer-panel3 > ul > li > a:hover {
    color: #3498db;
    font-size: 15px;
}

.footer-copyright {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: #1b1b1b;
    color: #929292;
    font-size: 12px;
    letter-spacing: 2px;
}



@media only screen and (max-width: 600px) {

    .footer-panel2, .footer-panel3 {
        display: none;
    }

  }