.quantity-container {
}

.quantity-up {
    color: #929292;
    cursor: pointer;
}

.quantity-value {
    color: #929292;
    font-size: 1em;
    font-weight: bold;
}

.quantity-down {
    color: #929292;
    cursor: pointer;
}