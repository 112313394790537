.review-container {
    flex-grow: 1;
}

.review-banner {
    font-family: var(--base-font);
    background-color: #f5f5f5 ;
    padding: 20px 10%;

}

.review-productarea {
    display: flex;
    padding: 20px 10%;
    
}

.review-productarea > table {
    width: 100%;
    text-align: center;
}

.review-table-header {
    font-family: var(--base-font);
    letter-spacing: 0.1em;
    background-color: var(--primary);
    color: white;
    border-bottom: 1px solid #ddd;
}

.review-table-image {
    max-width: 50px;
    width: 10%;
    padding: 5px;
}

.review-table-image > img {
    width: 100%;
    height: auto;
}

.review-table-desc {
    position: relative;
}

.review-table-desc > h5 {
    margin: 0;
    padding: 5px;
    font-family: var(--alt-font);
    font-size: 1em;

    display: flex;
    margin-left: 10px;
}

.review-table-desc > p {
    margin: 0;
    padding: 2px;
    font-size: 0.7em;
    font-family: var(--base-font);

    display: flex;
    margin-left: 10px;
}

.review-table-desc  span {
    color: #929292;
}

.review-table-quantity {
    font-size: 1.2em;
}

.review-table-quantity > h4 {
    color: #929292;
    margin: 0;
    padding: 5px;
}

.review-table-unit {
    font-size: 1.2em;
}

.review-table-unit > h4 {
    margin: 0;
    padding: 5px;
}

.review-table-total {
    font-size: 1.2em;
    color: var(--nav-color-alt);
}

.review-table-total > h4 {
    margin: 0;
    padding: 5px;
}

.review-productarea > table td {
    border-bottom: 1px solid #ddd;
}

.review-user-details {
    
    display: grid;
    grid-template-areas: "heading heading heading edit"
                         "name name name name"
                         "address address address address"
                         "suburb suburb suburb suburb"
                         "country country country country"
                         "email email email email"
                         "number number number number"
                         "pickup pickup pickup pickup"
                         "dropoff dropoff dropoff dropoff"
                         ;
    
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 20px 10%;
    padding: 10px;
    row-gap: 1px;
    column-gap: 1px;
    border: 1px solid #ccc;
}

.review-edit-btn {
    grid-area: edit;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
}

.review-edit-btn > svg {
    margin-left: 5px;
}

.review-edit-btn > p {
    margin: 0;
}


.review-edit-btn:hover {
    color: var(--primary);
}

.review-user-details > h4, .review-user-details > p {
    margin: 0;
    font-family: var(--base-font);
}

.review-user-details > p {
    font-size: 0.8em;
}

.review-heading {
    grid-area: heading;
    
}

.review-name {
    grid-area: name;
    
}

.review-address {
    grid-area: address;
    
}

.review-suburb {
    grid-area: suburb;
    
}

.review-country {
    grid-area: country;
    
}

.review-email {
    grid-area: email;
    
}

.review-number {
    grid-area: number;
    
}

.review-pickup {
    grid-area: pickup;
}

.review-dropoff {
    grid-area: dropoff;
}

.review-subtotal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10%;
    margin-left: 10%;
    border-top: 1px solid #ddd;
    font-family: var(--base-font);
}

.review-subtotal-wrapper {
    display: flex;
    align-items: center;
}

.review-subtotal-wrapper > h3 {
    color: #929292;
}

.review-subtotal-wrapper > p {
    padding: 5px;
    font-weight: bold;
    color: var(--nav-color-alt);
}

.review-user-details > div > input {
    outline: none;
    border: 1px solid var(--primary);
    padding: 3px;
    font-family: var(--base-font);
}

.review-user-details > div > input:focus {
    border: 2px solid var(--secondary-variant);
}

.btn-primary.editing {
    background-color: #fff;
    color: dimgrey;
    border: 1px solid dimgrey;
}

.review-clock { 
    display: flex;
    margin-top: 5px;
    border: 1px solid var(--primary);
    font-family: var(--base-font);
    font-size: 0.8em;
    width: 160px;
}



.review-dropoff > p, .review-pickup > p{
    font-size: 0.8em;
    margin: 2px;
}

.review-controls {
    margin: 20px 10%;
    border: 1px solid #ccc;
}

.review-delivery-input {
    display: flex;
    flex-direction: column;
    font-family: var(--base-font);
    font-weight: bold;
    padding: 5px;
}

.review-delivery-input > input {
    width: 50px;
}

.review-bond-input, .review-discount-input {
    display: flex;
    flex-direction: column;
    font-family: var(--base-font);
    font-weight: bold;
    padding: 5px;
}

.review-bond-input > input, .review-discount-input > input {
    width: 50px;
}

.check-style {
    color: yellowgreen;
    position: absolute;
    font-size: 1.4em;
    top: 10px;
    right: 10px;
}

.hide {
    display: none;
}


@media only screen and (max-width: 600px) {

    .review-productarea {
        display: flex;
        padding: 20px 1%;
        font-size: 12px;
    }

    .review-table-header {
        font-size: 0.6rem;
    }

    .review-user-details {
        margin: 20px 1%;
    }

    .review-subtotal {
        margin-right: 1%;
        margin-left: 1%;
    }

    .review-table-desc {
        text-align: left;
    }

    .review-controls {
        margin: 20px 1%;
    }
    

  }
