.product-container {
    display: flex;
    min-height: 100%;
    height: auto;
    flex-grow: 1;
    flex-direction: column;
    margin-top: 5px;
}

.product-banner {
    font-family: var(--base-font);
    background-color: #f5f5f5 ;
    padding: 20px 10%;
}

.product-banner > h2 {
    margin: 0;
    padding: 0;
    color: #333333;
}
.product-banner > p {
    color: #929292;
   
}

.product-area {
    padding: 20px 10%;
    min-height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
    column-gap: 5px;
    row-gap: 5px;
}

@media only screen and (max-width: 600px) {
    
    .product-area {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 5px;
        row-gap: 5px;
    }

  }