.cart-container {
    position: relative;
}

.cart-wrapper:first-child:hover{
    color: purple;
}

.cart-wrapper:hover > p{
    background-color: purple ;
    border-color: purple;
}


.cart-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
    cursor: pointer;
}

.cart-wrapper > p{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border: 1px solid var(--primary);
    background-color: var(--primary);
    margin: 0;
    border-radius: 50px;
    color: #fff;
}

.cart-icon {
    font-size: 15px;
    margin: 5px;
}

.cart-checkout-container {
    width: 300px;
    height: auto;
    border: 1px solid #ccc;
    position: absolute;
    background-color: white;
    z-index: 100;
}

.disappear {
    display: none;
}

@media only screen and (max-width: 600px) {
    .cart-checkout-container {
        transform: translateX(-80%);
    }

    .cart-container {
        font-display: flex;
    }
    

  }