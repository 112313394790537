.login-container {
    flex-grow:1;
    font-family: var(--base-font);
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-card {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    width: 80%;
    min-width: 300px;
    height: auto;
    padding: 5px;
    background-color: #fafafa;
}

.login-header {
    display: flex;
    justify-content: center;
    color: var(--primary);
}

.login-header > h4 {
    margin: 0;
    padding: 5px;
}

.login-main {
    display: flex;
    flex-direction: column;
    
}

.login-button {
    width: 60%;
    min-width: 200px;
    align-self: center;
}

.login-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--error);
}

.login-persis{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-link {
    color: var(--primary);
    cursor: pointer;
    text-decoration: underline;
}

.login-link:hover {
    color: var(--secondary);
}