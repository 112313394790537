.group-container{
    margin: 10px;
    padding-bottom: 3px;
    background-color: rgba(0, 174, 255, 0.137);
}

.group-header {
    display: flex;
    align-items: center;
    background-color: #ddd;
    cursor: pointer;

    color: var(--nav-color);

    grid-column: 1 /3;
}

.group-header > * {
    margin: 4px;
}