.sidebar-container {
    display: none;
}

.sidebar-icon {
    font-size: 2em;
}

.sidebar-menu {
    position: fixed;
    top: 0;
    left: -1000%;
    
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100%;
    height: auto;

    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.4s ease-in-out ;
}

.fadeIn {
    background-color:rgba(0, 0, 0, 0.411);
    left: 0;
    z-index: 5000;
}


.sidebar-header {
    position: relative;
    left: -500px;

    display: flex;
    justify-content: center;
    width: 50%;
    padding: 10px 5px 10px 5px;
    background-color: var(--surface);
    min-width: 300px;
    border-bottom: 1px solid #ddd ;
}

.sidebar-header > img {
    width: 90%;
    height: auto;
}

.sidebar-main {
    position: relative;
    left: -500px;

    display: flex;
    flex-direction: column;
    width: 50%;
    min-width: 300px;

    flex-grow: 1;
    background-color: var(--surface);
}

.slideIn {
    left: 0;
    transition: left 0.8s ease-in-out;
}

.sidebar-item {
    padding: 10px;
    text-decoration: none;
    font-size: 1.2em;

    color: var(--nav-color);
}

.sidebar-item:hover {
    background-color: #ccc;
}

.sidebar-submenu {
    margin-left: 15px;
}

@media only screen and (max-width: 600px){
    .sidebar-container {
        display: flex;
        flex-grow: 1;
    }
}

