.final-container {
    flex-grow: 1;
}

.final-card {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    min-height: 500px;
    margin-top: 20px;
    border: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    font-family: var(--base-font);

}

.final-card > h4 {
    text-align: center;
}

.final-loader {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.final-message {
    display: flex;
    font-family: var(--alt-font);
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 600px) {

    .final-card {
        min-height: 400px;
    }

  }