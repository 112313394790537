@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap"');

body {
    --primary: #0C9EFF;
    --primary-variant: #1565c0;
    --secondary: #ff9800;
    --secondary-variant: #f57c00;
    --background: #eceff1;
    --surface: #ffffff;
    --error: #c62828;
    --on-primary: #ffffff;
    --on-secondary: #000000;
    --on-background: #ffffff;
    --on-surface: #292a2b;
    --on-error: #ffffff;

    --custom-gray: #C7CACC;

    --base-font: 'Roboto', sans-serif;
    --alt-font: "Open Sans",sans-serif;

    --nav-color: #1e73be;
    --nav-color-alt: #57bf6d;
}

html,
body, #root {
    width: 100%;
    height: 100%;
}

.App {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100%;
    height: auto;
    overflow: hidden;
}

.btn-primary {
    background-color: var(--primary);
    border: 1px solid var(--primary);
    color: var(--surface);
    padding: 5px;
    font-family: var(--base-font);
    margin: 5px;
    font-weight: bold;
}

.btn-primary:hover {
    background-color: #fff;
    
    color: var(--primary);
}

.btn-primary.large {
    width: 100%;
    margin: 0;
    text-align: center;
    font-size: 1.2em;
}

.btn-secondary {
    background-color: var(--secondary);
    border: 1px solid var(--secondary);
    color: var(--surface);
    padding: 5px;
    font-family: var(--base-font);
    margin: 5px;
    font-weight: bold;
}

.btn-secondary:hover {
    background-color: #fff;
    
    color: var(--secondary);
}

.btn-secondary.large {
    width: 100%;
    margin: 0;
    text-align: center;
    font-size: 1.2em;
}
