.orderitem-container {
    border: 1px solid #738fb97e;
    margin: 10px;
    padding: 5px;

    background-color: #fff;
}

.orderitem-customer-details{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;

}

.orderitem-cs-dt-header {
    display: flex;
    align-items: center;
    background-color: #ddd;
    cursor: pointer;

    color: var(--nav-color);

    grid-column: 1 / 3;

}

.orderitem-cs-dt-header > * {
    margin: 4px;
}

.orderitem-cs-dt-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 5px;
}

.orderitem-cs-dt-item > *{
    margin: 0;
}

.orderitem-cs-dt-item > p{
    margin-left: 2px;
    font-size: 0.8em;
}

.orderitem-cs-dt-item.full {
    grid-column: 1 / 3;
}

.hidden {
    display: none;
}

.orderitem-cs-dt-item.table{
    flex-direction: column;
}

.orderitem-cs-dt-item > table {
    width: 100%;
    border-collapse: collapse;
}

.orderitem-cs-dt-item > table > tr th {
    text-align: left;
}

.orderitem-id {
    display: flex;
    justify-content: flex-end;
    align-items: right;
    color: var(--primary-variant)
}

.orderitem-id > p {
    font-style: italic;
    font-size: 0.6em;
    margin-bottom: 0;
}

.orderitem-id.full{
    grid-column: 1 / 3;
}

.orderitem-date {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    font-style: italic;
    
    font-size: 0.6em;
    align-items: center;
}

.orderitem-edit {
    padding: 3px;
    padding-right: 10px;
    font-style: normal;

    font-size: 0.7rem;
    font-weight: bold;
    cursor: pointer;
}

.orderitem-edit:hover {
    color: var(--secondary);
}

.orderitem-buttons {
    grid-column: 1 /3;
    display: flex;
    justify-content: center;

}

.orderitem-buttons > button {
    width: 40%;
}

.orderitem-response {
    grid-column: 1 /3;
    color: var(--nav-color-alt);
}

.btn-primary.disabled {
    background-color: #fff;
    color: dimgrey;
    border: 1px solid dimgrey;
}
