.input-container {
    font-family: var(--base-font);
    display: flex;
    flex-flow: column-reverse;
    margin-bottom: 10px;
    margin: 2px;
    padding: 2px;
    max-width: 100%;
    overflow: hidden;
}

.input-container > input, .input-container > label {
    transition: all 0.2s;
   touch-action: manipulation;
}

.input-container > input{
    font-size: 1.3em;
    
    border: 1px solid rgba(121, 162, 250, 0.486);
    -webkit-appearance: none;
    border-radius: 3px;
    padding: 2px;
    cursor: text;
}


.input-container > input:focus{
    outline: 0;
    border: 1px solid var(--primary);
}

.input-container > label {
    font-size: 0.7em;
    color: var(--primary);
    padding: 2px;
    padding-left: 5px;
    padding-bottom: 5px;
  }

  .input-container > input:placeholder-shown + label {
    cursor: text;
    max-width: 66.66%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(0, 2.125rem) scale(1.5);
  }

  .input-container > input::-webkit-input-placeholder {
    opacity: 0;
    transition: inherit;
  }

  .input-container > input:focus::-webkit-input-placeholder {
    opacity: 0;
  }


  .input-container > input:not(:placeholder-shown) + label,
  .input-container > input:focus + label {
  transform: translate(0, 0) scale(1);
  cursor: pointer;
}
