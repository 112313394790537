.edit-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.edit-container > h3 {
    color: var(--nav-color);
}

.edit-error {
    align-self: center;
    font-weight: 400;
    
}

.edit-input-container {
    display: flex;
    flex-direction: column;
    padding: 5px;
    border-bottom: 1px solid #ccc;
}

.edit-input-label {
    font-size: 0.7rem;
    font-weight: bold;
    padding-bottom: 3px;
}

.edit-input-text {
    padding: 5px;
    outline: none;
    border: 0.5px solid var(--nav-color);
    max-width: 300px;
}

.edit-datepicker {
    border-color: var(--nav-color);
    width: 300px;
}

.edit-datepicker > input {
    border-color: var(--nav-color);
}

.edit-clock {
    display: flex;
    margin-top: 5px;
    border: 1px solid var(--nav-color);
    font-family: var(--base-font);
    font-size: 0.8em;
    width: 300px;
}

.edit-divider {
    width: 100%;
    border: 0.5px solid rgb(141, 141, 141);
}

.edit-cart-item {
    border: 1px solid black;
    padding: 3px;
    margin: 2px;
    display: flex;
    align-items: center;
}

.edit-cart-item > p {
    margin: 0;
}

.edit-cart-item > input {
    outline: none;
    border: 0.5px solid #dddddd;
    padding: 3px;
    max-width: 50px;
    margin-right: 10px;
}

.edit-cart-item > input:focus {
    border-color: var(--primary);
}

.edit-cart-item > span {
    color: var(--nav-color-alt);
    margin-left: 2%;
}

.edit-input-dropdown {
    outline: none;
    padding: 5px;
    min-width: 250px;
    width: 50%;
    border: 1px solid #ddd;
}

.edit-input-dropdown:focus {
    border-color: var(--primary);
}

.edit-input-text.readonly {
    border-color: #929292;
    font-size: 18;
}

.edit-buttons {
    display: flex;
    justify-content: center;
}

.edit-buttons > button {
    width: 40%;
}

.edit-loader {
    display: flex;
    justify-content: center;
}

.edit-remove-item {
    margin: 0;
    padding: 3px;
    font-size: 1.4rem;
    color: var(--primary);
    margin-left: auto;
}

.edit-remove-item:hover {
    color: var(--primary-variant)
}