.terms-container {
    min-height: 100%;
    height: auto;
    flex-grow: 1;
    flex-direction: column;
    margin-top: 5px;

    display: grid;
    grid-template-columns: 1fr 1fr;
}

.terms-banner {
    font-family: var(--base-font);
    background-color: #f5f5f5 ;
    padding: 20px 10%;

    grid-column:  1 / 3;
}

.terms-banner > h2 {
    margin: 0;
    padding: 0;
    color: #333333;
}

.terms-content {
    padding: 20px 10%;
    font-family: var(--base-font);
}

.terms-content h3 {
    color: #333333;
}

.terms-content p {
    color: #929292;
}

.terms-content ul {
    color: #929292;
    list-style-type: number;
    font-size: 0.9rem;
}

@media only screen and (max-width: 600px) {
    
    .terms-container {
        display: flex;
    }
  }