.details-container {
    flex-grow:1;
    font-family: var(--base-font);
    
}

.details-banner {
    font-family: var(--base-font);
    background-color: #f5f5f5 ;
    padding: 20px 10%;
}

.details-banner > h2 {
    margin: 0;
    padding: 0;
    color: #333333;
}

/* -------------------left hand panel----------------- */

.details-panel {
    padding: 20px 10%;
    margin-top: 4%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5px;
}

.details-panel > img {
    /* width: 100%; */
    height: auto;
    max-width: 100%;
}


/* -------------------right hand panel----------------- */
.details-item {
    padding: 10px 5%;
}

.details-part1 {
    font-size: 25px;
    border-bottom: 1px solid #ddd;
}

.details-part1 > h4 {
    margin: 0;
    margin-bottom: 20px;
}

.details-part2 {
    color: #929292;
    font-size: 15px;
    border-bottom: 2px solid #ddd;
}

.details-part2 > h5 {
    font-size: 35px;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #57bf6d;
}

/* -------------------3rd Panel----------------- */
.details-part3 {
    padding: 10px;
    border-bottom: 2px solid #ddd;
    display: flex;
    flex-direction: column;
}

.details-part3-timers > p {
    font-family: "Open Sans",sans-serif;
    color: #929292;
    margin: 5px;
    margin-left: 0;
    font-size: 0.9em;
}

.details-part3-timers {
    border-top: 1px solid #ddd;
    padding-bottom: 5px;
}

.details-cart-input {
    display: flex;
    
}

.details-counter {
    width: 150px;
    height: 50px;
    display: flex;
    padding: 5px;
    color: #929292;
}

.minus {
    flex-grow: 1;
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.count {
    flex-grow: 1;
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
}

.plus {
    flex-grow: 1;
    border: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.details-clock { 
    display: flex;
    margin-top: 5px;
    padding: 5px;
    border: 1.5px solid rgba(121, 162, 250, 0.486);
    width: 100%;
}

.react-time-picker__wrapper {
    border: none !important;
} 

.react-time-picker__inputGroup__input {
    font: var(--base-font) !important;
}

.detail-error {
    font-size: 0.8em;
    color: var(--error);
    letter-spacing: 2px;
}

/* -------------------4rd Panel----------------- */

.details-part4 {
    font-size: 12px;
    border-bottom: 1px solid #ddd;
    padding: 5px;
}

.details-part4 > span {
    display: flex;
    align-items: center;
    color: #929292;
}

.details-part4 > span > p {
    margin: 2px;
    font-weight: bold;
    color: black;
}

/* -------------------description----------------- */
.details-desc {
    padding: 20px 10%;
}
.details-desc-heading {
    display: flex;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    justify-content: center;
    align-items: center;
}

.details-desc-heading > h3{
    margin: 0;
    padding: 10px;
    font-size: 15px;
    font-family: "Open Sans",sans-serif;
    border-top: 2px solid #1e73be;
    border-bottom: 2px solid #1e73be;
    color:  #1e73be;
}

.details-desc > h5 {
    font-size: 20px;
}

.details-desc > p {
    color: #929292;
    margin: 0;
}

@media only screen and (max-width: 600px) {

    .details-panel {
        padding: 10px 5%;
        margin-top: 4%;
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 5px;
    }

  }