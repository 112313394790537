.cartitem-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 5px;
    font-family: var(--base-font);
    color: black;
    border-bottom: 1px solid #ddd;
    position: relative;
}

.cartitem-container > img {
    width: 100%;
    height: auto;
}

.cartitem-infowrapper {
    padding: 2px;
}

.cartitem-infowrapper > h4{
    margin: 0;
    font-size: 15px;
}

.cartitem-infowrapper > span {
    display: flex;
    align-items: center;
    color: #929292;
    font-size: 0.85em;
}

.cartitem-infowrapper > span > h5 {
   margin: 0;
   color: black;
   margin-right: 2px;
}

.cartitem-price {
    display: flex;
    align-items: center;
    padding-top: 3px;
}

.cartitem-price > h5 {
    margin: 2px;
    color: #57bf6d;
    font-size: 14px;
}

.cartitem-price > p {
    color: #929292;
    margin: 2px;
    font-size: 12px;
}

.cartitem-remove {
    position: absolute;
    top: 0;
    right: 0;

    width: 20px;
    height: 20px;

    font-weight: bold;

    border: 1.5px solid #929292;
    color: #929292;
    margin: 5px;
    border-radius: 45px;

    text-align: center;
    cursor: pointer;
}

.cartitem-remove:hover {
    background-color: #929292;
    color: white;
}