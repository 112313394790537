.mini-container {
    display: flex;
    height: 30px;
    width: 160px;
    align-items: center;
    border: 1px solid var(--primary);
    cursor: pointer;
    
}

.mini-container  > input{
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    border-right: 1px solid #ccc;
    text-align: center;
    font-family: var(--base-font);
    letter-spacing: 2px;
    color: #929292;
    cursor: pointer;
}

.mini-icon {
    font-size: 40px;
    padding: 8px;
    color: #1e73be;
    cursor: pointer;
}

