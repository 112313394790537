.calendar-container {
    display: flex;
    height: 40px;
    align-items: center;
    border: 1.5px solid rgba(121, 162, 250, 0.486);
    cursor: pointer;
    
}

.calendar-container  > input{
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    border-right: 1px solid #ccc;
    text-align: center;
    font-family: var(--base-font);
    letter-spacing: 2px;
    color: #929292;
    cursor: pointer;
}

.calendar-icon {
    font-size: 45px;
    padding: 8px;
    color: #1e73be;
    cursor: pointer;
}

