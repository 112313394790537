.nav-container {
    display: flex;
    padding: 20px;
    align-items: center;
    font-family: var(--base-font);
    font-size: 0.8em;
    background-color: #fff;
    color: var(--nav-color);
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.55);

}

.nav-image {
    width: 20%;
    height: auto;
}

.nav-container > a:link, .nav-item-end > a:link {
    text-decoration: none;
    color: var(--nav-color);
}

.nav-container > a:visited, .nav-item-end > a:visited {
    text-decoration: none;
    color: var(--nav-color);
}

.nav-container > a:hover, .nav-item-end > a:hover {
    text-decoration: none;
    color: rgb(166, 32, 255);
}

.nav-item {
    padding-left: 20px;
    padding-right: 20px;
}

.nav-item-end {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
}



@media only screen and (max-width: 600px) {
    .nav-item {
        display: none;
    }

    .nav-item-end {
        display: none;
    }


  }