.progress-container {
    background-color: rgb(196, 196, 196);
    flex-grow: 1;
    height: 10px;
    margin: 5px;
    border-radius: 20px;
}

.progress-bar {
    background-color: rgb(118, 247, 118);
    height: 10px;
    border-radius: 20px;
    transition: width 2s,
}