.uploader-container {
    grid-column: 1/5;
    padding: 5px;
    margin: 5px;
    border: 1px solid rgba(121, 162, 250, 0.486);
    font-family: var(--base-font); 
}

.uploader-container >h4 {
    margin: 2px;
    color: var(--primary);
}

.uploader-input {
    display: flex;
    align-items: center;
}

.uploader-input > p{
    flex-grow: 1;
    border-bottom: 1px solid rgba(121, 162, 250, 0.486);
}

.uploader-input > input[type="file"] {
    display: none;
}

.uploader-chooser {
    display: flex;
    flex-grow: 1;
    align-items: center;
    font-family: var(--base-font);
}

.uploader-chooser > h5 {
    background-color: var(--primary);
    border: 1px solid var(--primary);
    color: var(--surface);
    padding: 5px;
    margin: 5px;
    font-weight: bold;
}
.uploader-chooser > h5:hover {
    background-color: #fff;
    color: var(--primary);
}

.uploader-chooser > p {
    margin: 0;
    flex-grow: 1;
    font-size: 0.9em;
}
